var home = function()
{
    var page = 1;

    var init = function () {
        let fl_especie = document.getElementById('fl_especie');
        if(fl_especie) {
            fl_especie.addEventListener('change', function() {
                size(this.value);
            });
        }
        let loadBtn = document.getElementById('loadBtn');
        if(loadBtn) {
            loadBtn.addEventListener('click', function() {
                load(this)
            });
        }
    }

    var size = function (especie) {
        let div = document.getElementById('fl-size');
        if(especie === '1') div.classList.remove('d-none');
        else div.classList.add('d-none');
    }

    var load = function (btn) {
        makeRequest(window.location.href+'?page='+(++page), 'GET', function (data) {
            document.getElementById('lista-casos').insertAdjacentHTML('beforeend', data.view);
            if (data.view == '') {
                btn.style.visibility = 'hidden'
            }
        })
    }

    var makeRequest = function (url, method, action) {
        let options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
        }
        fetch(url, options)
        .then(function(response) { return response.json(); })
        .then(function(data) {
            action(data.response.data)
        }).catch(function(e) { console.log(e) });
    }

    return {
        init: init,
    };
}();

document.addEventListener("DOMContentLoaded", function(event) {
    home.init();
});
